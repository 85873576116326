<template>
  <v-app>
    <v-app-bar app color="black" class="white--text">
      <v-app-bar-nav-icon
        app
        class="hidden-md-and-up"
        v-if="isAuth"
        @click.stop="drawer = !drawer"
        large
        dark
      ></v-app-bar-nav-icon>
      <div class="pt-5">
        <v-img
          class="shrink md-and-up"
          v-if="isAuth"
          max-width="150"
          min-height="35"
          src="@/assets/fuelomat_inverzni_fit.svg"
        ></v-img>
        <p class="text-caption">
          WebUI2 version: {{ $store.state?.packageVersion || "--" }}
        </p>
      </div>
      <v-toolbar-title v-if="isAuth" class="text-h5 ml-10">
        {{ $t("ID: ") + $store.state.station?.code || "" }}</v-toolbar-title
      >
      <v-toolbar-title v-if="isAuth" class="text-h5 ml-10">
        {{
          $t("Name: ") + $store.state.station?.stn_name || ""
        }}</v-toolbar-title
      >
      <template v-if="isAuth" smAndUp v-slot:extension>
        <v-tabs class="hidden-sm-and-down" dark align-with-title>
          <v-tab
            v-for="(item, i) in tabs1"
            link
            :to="item.link"
            :key="i"
            v-show="$auth.check(item.role)"
            >{{ $t(item.name) }}</v-tab
          >
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-tab color="primary" dark v-bind="attrs" v-on="on">
                {{ $t(submenu_system.name) }}
              </v-tab>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, i) in tabs3"
                link
                :to="item.link"
                :key="i"
                v-show="$auth.check(item.role)"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-tab color="primary" dark v-bind="attrs" v-on="on">
                {{ $t(submenu_settings.name) }}
              </v-tab>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, i) in tabs2"
                link
                :to="item.link"
                :key="i"
                v-show="$auth.check(item.role)"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-tabs>
      </template>

      <v-spacer></v-spacer>

      <v-menu bottom left offset-x offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn attach icon v-bind="attrs" v-on="on">
            <v-icon color="white">mdi-translate</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item dense @click="translate('cs')">
            <v-list-item-icon>
              <v-img
                src="https://flagcdn.com/cz.svg"
                width="35"
                alt="CZ"
              ></v-img>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Czechia") }}</v-list-item-title>
          </v-list-item>
          <v-list-item dense @click="translate('en')">
            <v-list-item-icon>
              <v-img
                src="https://flagcdn.com/gb.svg"
                width="35"
                alt="EN"
              ></v-img>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("English") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu bottom left offset-x offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon color="white">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item dense @click="logout()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary dark>
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-icon large>mdi-account</v-icon>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ $auth.user()?.name }}
            </v-list-item-title>
            <v-list-item-subtitle>{{
              $auth.user()?.first_name
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item v-for="(item, i) in tabs1" link :to="item.link" :key="i">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
        </v-list-item>

        <v-list-group
          :value="false"
          no-action
          :prepend-icon="submenu_system.icon"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                v-text="$t(submenu_system.name)"
              ></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(item, i) in tabs3"
            link
            :to="item.link"
            :key="i"
            v-show="$auth.check(item.role)"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
          :value="false"
          no-action
          :prepend-icon="submenu_settings.icon"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                v-text="$t(submenu_settings.name)"
              ></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(item, i) in tabs2"
            :key="i"
            link
            :to="item.link"
            v-show="$auth.check(item.role)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="$t(item.name)"></v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-main style="overflow-y: auto">
      <v-row justify="center" class="mt-5">
        <v-alert v-model="alert" :type="type" dismissible width="500">
          {{ text }}</v-alert
        >
      </v-row>

      <v-container>
        <router-view @alertMsg="alertMsg" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},

  data() {
    return {
      drawer: false,
      alert: false,
      type: "error",
      text: "",
      tabs1: [
        { id: 1, link: "/sites", icon: "mdi-sitemap", name: "sites" },
        { id: 2, link: "/home", icon: "mdi-home", name: "home" },
        { id: 3, link: "/vehicles", icon: "mdi-car", name: "vehicles" },
        { id: 4, link: "/drivers", icon: "mdi-account-group", name: "drivers" },
        {
          id: 5,
          link: "/transactions",
          icon: "mdi-table",
          name: "transactions",
        },
      ],
      submenu_settings: {
        id: 5,
        link: "/main",
        icon: "mdi-cog",
        name: "settings",
        role: ["1", "2"],
      },
      submenu_system: {
        id: 0,
        link: "/main",
        icon: "mdi-database-cog",
        name: "system",
        role: ["1", "2"],
      },
      tabs2: [
        {
          id: 0,
          link: "/analog",
          icon: "mdi-led-on",
          name: "analog",
          role: ["1"],
        },
        {
          id: 1,
          link: "/buses",
          icon: "mdi-bus-multiple",
          name: "buses",
          role: ["1"],
        },
        {
          id: 2,
          link: "/devices",
          icon: "mdi-connection",
          name: "devices",
          role: ["1"],
        },
        {
          id: 3,
          link: "/pumps",
          icon: "mdi-gas-station",
          name: "pumps",
          role: ["1"],
        },
        {
          id: 4,
          link: "/nozzles",
          icon: "mdi-alpha-n-box",
          name: "nozzles",
          role: ["1", "2"],
        },
        {
          id: 5,
          link: "/probes",
          icon: "mdi-thermometer",
          name: "probes",
          role: ["1"],
        },
        {
          id: 6,
          link: "/tanks",
          icon: "mdi-beaker",
          name: "Tanks",
          role: ["1"],
        },
        {
          id: 7,
          link: "/calibration",
          icon: "mdi-tune-vertical-variant",
          name: "calibration",
          role: ["1"],
        },
      ],
      tabs3: [
        {
          id: 0,
          link: "/main",
          icon: "mdi-database-cog",
          name: "Main",
          role: ["1", "2"],
        },
        {
          id: 1,
          link: "/station",
          icon: "mdi-store-edit",
          name: "Station",
          role: ["1"],
        },
        {
          id: 2,
          link: "/ipsetting",
          icon: "mdi-ip-network",
          name: "Network setting",
          role: ["1"],
        },
        {
          id: 3,
          link: "/password",
          icon: "mdi-form-textbox-password",
          name: "Password",
          role: ["1"],
        },
        {
          id: 4,
          link: "/date",
          icon: "mdi-clock",
          name: "Date/Time",
          role: ["1", "2"],
        },
        {
          id: 5,
          link: "/tagreader",
          icon: "mdi-nfc",
          name: "Tag Reader",
          role: ["1"],
        },
        {
          id: 6,
          link: "/language",
          icon: "mdi-translate",
          name: "Language",
          role: ["1", "2"],
        },
        {
          id: 7,
          link: "/updates",
          icon: "mdi-update",
          name: "updates",
          role: ["1", "2"],
        },
        {
          id: 8,
          link: "/backup",
          icon: "mdi-database-export",
          name: "Backup",
          role: ["1", "2"],
        },
        {
          id: 9,
          link: "/webservices",
          icon: "mdi-api",
          name: "webservices",
          role: ["1"],
        },
        {
          id: 9,
          link: "/watchdog",
          icon: "mdi-dog",
          name: "watchdog",
          role: ["1"],
        },
        {
          id: 10,
          link: "/logs",
          icon: "mdi-file",
          name: "logs",
          role: ["1", "2"],
        },
      ],
    };
  },

  methods: {
    translate(lang) {
      this.$i18n.locale = lang;
    },
    logout() {
      this.$store.dispatch("setStation", null);
      this.$auth.logout({ redirect: { name: "Login" } });
    },
    alertMsg(msg) {
      this.type = msg.type;
      this.text = msg.text;
      this.alert = true;
      setTimeout(() => {
        this.alert = false;
      }, 5000);
    },
  },
  computed: {
    station() {
      return this.$store.getters.getStation();
    },
    isAuth() {
      return this.$auth.check();
    },
  },
  created() {
    this.$store.dispatch("setStation");
  },
};
</script>
