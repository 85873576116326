export const SOCKET_ONOPEN = 'SOCKET_ONOPEN'
export const SOCKET_ONCLOSE = 'SOCKET_ONCLOSE'
export const SOCKET_ONERROR = 'SOCKET_ONERROR'
export const SOCKET_ONMESSAGE = 'SOCKET_ONMESSAGE'
export const SOCKET_RECONNECT = 'SOCKET_RECONNECT'
export const SOCKET_RECONNECT_ERROR = 'SOCKET_RECONNECT_ERROR'
export const PUMP_MESSAGE = 'PUMP_MESSAGE'
export const PUMP_COUNT = 'PUMP_COUNT'
export const PUMP_AUTH_KEY = 'PUMP_AUTH_KEY'
export const NOZZLE_MAC = 'NOZZLE_MAC'
export const DELETE_NOZZLE_MAC = 'DELETE_NOZZLE_MAC'
export const SET_STATION = 'SET_STATION'
export const GET_ANALOG_SETTING = 'GET_ANALOG_SETTING'
export const SET_ANALOG_SETTING = 'SET_ANALOG_SETTING'
export const SET_ANALOG_STATUS = 'SET_ANALOG_STATUS'
export const UPDATE_ANALOG_SETTING = 'UPDATE_ANALOG_SETTING'
export const CLEAR_STATUSES = 'CLEAR_STATUSES'
