import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', 
    redirect: '/sites',
   
  },
  
  {
    path: '/sites',
    name: 'SitesSummary', 
    component: () => import(/* webpackChunkName: "sites" */ '../views/SitesSummary'),
    meta: {
      auth: true
    }
  },
  {
    path: '/home',
    name: 'Home', 
    component: () => import(/* webpackChunkName: "home" */ '../views/Home'),
    meta: {
      auth: true
    }
  },
  {
    path: '/vehicles',
    name: 'Vehicles',
    component: () => import(/* webpackChunkName: "vehicles" */ '../views/Vehicles.vue'),
    meta: {
      auth: true
    }
  }
  ,
  {
    path: '/drivers',
    name: 'Drivers',
    component: () => import(/* webpackChunkName: "drivers" */ '../views/Drivers.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: () => import(/* webpackChunkName: "transac" */ '../views/Transactions.vue'),
    meta: {
      auth: true
    }
  },
  
  {
    path: '/main',
    name: 'Main',
    component: () => import(/* webpackChunkName: "main" */ '../views/Main.vue'),
    meta: {
      auth: {
        roles: ["1","2"],
        redirect: "/forbidden"
      }
    }
  },
  {
    path: '/station',
    name: 'Station',
    component: () => import(/* webpackChunkName: "station" */ '../views/Station.vue'),
    meta: {
      auth: {
        roles: ["1"],
        redirect: "/forbidden"
      }
    }
  },
  {
    path: '/ipsetting',
    name: 'IPSetting',
    component: () => import(/* webpackChunkName: "ipsett" */ '../views/Ipsetting.vue'),
    meta: {
      auth: {
        roles: ["1"],
        redirect: "/forbidden"
      }
    }
  },
  {
    path: '/password',
    name: 'Password',
    component: () => import(/* webpackChunkName: "passwd" */ '../views/Password.vue'),
    meta: {
      auth: {
        roles: ["1"],
        redirect: "/forbidden"
      }
    }
  },
  {
    path: '/date',
    name: 'Date',
    component: () => import(/* webpackChunkName: "date" */ '../views/Datetime.vue'),
    meta: {
      auth: {
        roles: ["1", "2"],
        redirect: "/forbidden"
      }
    }
  },
  {
    path: '/tagreader',
    name: 'Tagreader',
    component: () => import(/* webpackChunkName: "tagreader" */ '../views/Tagreader.vue'),
    meta: {
      auth: {
        roles: ["1"],
        redirect: "/forbidden"
      }
    }
  },
  {
    path: '/language',
    name: 'Language',
    component: () => import(/* webpackChunkName: "lang" */ '../views/Language.vue'),
    meta: {
      auth: {
        roles: ["1", "2"],
        redirect: "/forbidden"
      }
    }
  },
  {
    path: '/backup',
    name: 'Backup',
    component: () => import(/* webpackChunkName: "backup" */ '../views/Backup.vue'),
    meta: {
      auth: {
        roles: ["1"],
        redirect: "/forbidden"
      }
    }
  },
  {
    path: '/webservices',
    name: '/Webservices',
    component: () => import(/* webpackChunkName: "wss" */ '../views/Webservices.vue'),
    meta: {
      auth: {
        roles: ["1"],
        redirect: "/forbidden"
      }
    }
  },
  
  {
    path: '/analog',
    name: '/Analog',
    component: () => import(/* webpackChunkName: "analog" */ '../views/AnalogSettings.vue'),
    meta: {
      auth: {
        roles: ["1"],
        redirect: "/forbidden"
      }
    }
  },
  {
    path: '/buses',
    name: '/Buses',
    component: () => import(/* webpackChunkName: "buses" */ '../views/Buses.vue'),
    meta: {
      auth: {
        roles: ["1"],
        redirect: "/forbidden"
      }
    }
  },
  {
    path: '/devices',
    name: 'Devices',
    component: () => import(/* webpackChunkName: "devices" */ '../views/Devices.vue'),
    meta: {
      auth: {
        roles: ["1"],
        redirect: "/forbidden"
      }
    }
  },
  {
    path: '/pumps',
    name: 'Pumps',
    component: () => import(/* webpackChunkName: "pumps" */ '../views/Pumps.vue'),
    meta: {
      auth: {
        roles: ["1"],
        redirect: "/forbidden"
      }
    }
    
  },
  {
    path: '/pumpsettings',
    name: 'PumpSettings',
    component: () => import(/* webpackChunkName: "pumpsett" */ '../views/PumpSettings.vue'),
    meta: {
      auth: {
        roles: ["1"],
        redirect: "/forbidden"
      }
    }
  },
  {
    path: '/probes',
    name: 'Probes',
    component: () => import(/* webpackChunkName: "probes" */ '../views/Probes.vue'),
    meta: {
      auth: {
        roles: ["1"],
        redirect: "/forbidden"
      }
    }
  },
  {
    path: '/tanks',
    name: 'Tanks',
    component: () => import(/* webpackChunkName: "tanks" */ '../views/Tanks.vue'),
    meta: {
      auth: {
        roles: ["1"],
        redirect: "/forbidden"
      }
    }
  },
  {
    path: '/calibration',
    name: 'Calibration',
    component: () => import(/* webpackChunkName: "calibration" */ '../views/Calibration.vue'),
    meta: {
      auth: {
        roles: ["1"],
        redirect: "/forbidden"
      }
    }
  },
  {
    path: '/nozzles',
    name: 'Nozzles',
    component: () => import(/* webpackChunkName: "nozzles" */ '../views/Nozzles.vue'),
    meta: {
      auth: {
        roles: ["1", "2"],
        redirect: "/forbidden"
      }
    }
  },
  {
    path: '/updates',
    name: 'Updates',
    component: () => import(/* webpackChunkName: "updates" */ '../views/Updates.vue'),
    meta: {
      auth: {
        roles: ["1"],
        redirect: "/forbidden"
      }
    }
  },
  {
    path: '/logs',
    name: 'Logs',
    component: () => import(/* webpackChunkName: "logs" */ '../views/Logs.vue'),
    meta: {
      auth: {
        roles: ["1", "2"],
        redirect: "/forbidden"
      }
    }
  },
  {
    path: '/watchdog',
    name: 'Watchdog',
    component: () => import(/* webpackChunkName: "logs" */ '../views/Watchdog.vue'),
    meta: {
      auth: {
        roles: ["1"],
        redirect: "/forbidden"
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "forbidden" */ '../views/Forbidden.vue')
  },
  { path: "*", 
    component: () => import(/* webpackChunkName: "not_found" */ '../views/PageNotFound.vue') 
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
