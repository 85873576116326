
export function PumpObj () {
  this.quantity = 0
  this.price = 0
  this.sale = 0
  this.id = 0
  this.flag = ''
  this.driver = ''
  this.vehicle = ''
  this.bottombox = 0
  this.status = 0
  this.nozzle = 0
  this.product = ''
  this.virtual_inuse = 0
  this.volts = 0.0
  this.tamper = 2
}
