
export const getPumpPropById = (state) => (id) => {
  let r = state.pumps.statuses.find(element => element.id === id)
  return typeof r !== 'undefined' ? r : {}
}

export const getPumpProps = (state) => () => {
  return  state.pumps?.statuses || {}
  
}

export const getPumpCounter = (state) => () => {
  return state.pumps.count
}

export const getAuthKey = (state) => () => {
  return state.pumps.auth_key
}

export const getIsConnected = (state) => () => {
  return state.socket.isConnected
}

export const getUserLang = (state) => () => {
  return state.user.lang
}

export const getNozzlesMacs = (state) => {
  return state.nozzle_macs
}

export const getStation = (state) => () => {
  return state.station
}

export const getSettingById = (state) => (id) => {
  return state.analog_settings[id]
}