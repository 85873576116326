import Vue from 'vue'
import Vuex from 'vuex'
import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)

const sendMessageWS = (data) => {
  if (!Vue.prototype.$socket) {
    return
  }
  Vue.prototype.$socket.send(JSON.stringify(data))
}

// Vuex.Store.$socket = Vue.prototype.$socket

const state = {
  packageVersion: process.env.PACKAGE_VERSION || '0',
  socket: {
    isConnected: false,
    message: '',
    reconnectError: false
  },
  pumps: {
    statuses: [],
    count: 0,
    bb_ver: '',
    fm_ver: '',
    auth_key: null
  },
  station: {},
  nozzle_macs: [],
  analog_status: [],
  analog_settings:{}
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})
