import * as types from './mutation-types'
import {PumpObj} from './pumpobj'
import Vue from 'vue'
import {setStation} from './actions'


export default {
  // mutations for reconnect methods
  [types.SOCKET_RECONNECT] (state, count) {
    console.error(state, count)
    console.info('types.SOCKET_RECONECT')
  },
  [types.SOCKET_RECONNECT_ERROR] (state) {
    state.socket.reconnectError = true
  },
  [types.SOCKET_ONOPEN] (state, event) {
    // Vue.prototype.$socket = event.currentTarget
    state.socket.isConnected = true
  },
  [types.SOCKET_ONCLOSE] (state, event) {
    console.error(state, event)
    state.socket.isConnected = false
  },
  [types.SOCKET_ONERROR] (state, event) {
    console.error(state, event)
  },
  // default handler called for all methods
  [types.SOCKET_ONMESSAGE] (state, message) {
    state.socket.message = message
  },
  [types.PUMP_MESSAGE] (state, message) {
   
    let i = state.pumps.statuses.findIndex(element => element.id === message.id)
    if (i === -1) {
      console.log('-1')
      let n = new PumpObj()
      for (let m of Object.keys(message)) {
        n[m] = message[m]
      }
      state.pumps.statuses.push(n)
    } else {
      for (let m of Object.keys(message)) {
        state.pumps.statuses[i][m] = message[m]
      }
    }
    state.pumps.statuses.sort((a, b) => {
      return a.id - b.id
  })
  },
  [types.PUMP_COUNT] (state, message) {
    state.pumps.count = message.count
    state.pumps.bb_ver = message.bb_ver
    state.pumps.fm_ver = message.fm_ver
  },
  [types.PUMP_AUTH_KEY] (state, key) {
    state.pumps.auth_key = key
  },
  [types.NOZZLE_MAC] (state, obj) {
    state.nozzle_macs = obj.nozzles
  },
  [types.DELETE_NOZZLE_MAC] (state) {
    state.nozzle_macs = []
  },
  [types.SET_STATION] (state, obj) {
    state.station = obj
  },
  [types.SET_ANALOG_STATUS] ( state, obj) {
    let result
    for (let i = 0; i < obj.status.length; i++) {
      let index = state.analog_status.findIndex(x => x.block===obj.status[i].block);
      if (index === -1) {
        state.analog_status = [...state.analog_status, obj.status[i]]
      } else {
        state.analog_status.splice(index,1, obj.status[i])
      }
    } 

  },
  [types.SET_ANALOG_SETTING] (state, obj) {
    state.analog_settings = { ...state.analog_settings, ...obj.settings}
  },
  [types.UPDATE_ANALOG_SETTING] (state, obj) {
    state.analog_settings[obj.address.toString()] = { ...state.analog_settings[obj.address.toString()], ...obj}
  },
  [types.CLEAR_STATUSES] (state) {
    state.pumps.statuses = []
  }

}
