import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import vuetify from './plugins/vuetify'


import axios from 'axios'
import VueAxios from 'vue-axios'

import auth                  from '@websanova/vue-auth/dist/v2/vue-auth.common.js';
import driverAuthBearer      from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js';
import driverHttpAxios       from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js';

import store from './store'
import VueNativeSock from 'vue-native-websocket'


Vue.config.productionTip = false

axios.interceptors.response.use((response) => { return response}, (error) => {
  if (error.response?.status === 401) {
    Vue.auth.logout()
    //Vue.$router.push("/login")
  }

  return Promise.reject(error)
})

Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL =  "https://restapi.fuelomat.cz/api/v2"

Vue.prototype.$serverAPI = 'https://restapi.fuelomat.cz/api/v3'


const proto = location.protocol !== 'https:' ? 'wss://' : 'wss://'
Vue.prototype.$wsURL = proto + 'restapi.fuelomat.cz'+ '/ws-station' 

// process.env.NODE_ENV === 'development' ? 'ws://192.168.3.24/ws-station'
// : proto + 'restapi.fuelomat.cz'+ '/ws-station'

store.$socket = Vue.prototype.$socket

  
Vue.use(VueNativeSock, Vue.prototype.$wsURL, 
  {
    connectManually: true,
  })
  //{
  //store: store,
  //format: 'json',
  //reconnection: true, // (Boolean) whether to reconnect automatically (false)
  // reconnectionAttempts: 25, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 3000
//})

Vue.use(auth, {
    plugins: {
        http: Vue.axios, // Axios
        router: router,
    },
    drivers: {
      auth: driverAuthBearer,
      http: driverHttpAxios,
      router: driverRouterVueRouter,
    },
    options: {
        rolesKey: 'roles',
        notFoundRedirect: {name: 'user-account'},
        stores: ['storage', 'cookie'],
        loginData: {
          url: "https://restapi.fuelomat.cz/api/v3/auth/login",
          method: 'POST',
          enabled: true,
          addTrailingSlash: true
        },
        fetchData: {
          url: "https://restapi.fuelomat.cz/api/v3/auth/user",
          method: 'GET',
          enabled: true,
          addTrailingSlash: true
        },
        refreshData: {
          url: "https://restapi.fuelomat.cz/api/v3/auth/refresh",
          method: 'GET',
          enabled: false,
          addTrailingSlash: true
        }
    }
});



new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')


