import * as types from './mutation-types'
import axios from 'axios'
import store from '.'


// export const getAllMessages = ({ commit }) => {
//   api.getAllMessages(messages => {
//     commit(types.SOCKET_ONOPEN, {
//       messages
//     })
//   })
// }
//
// export const sendMessage = ({ commit }, payload) => {
//   api.createMessage(payload, message => {
//     commit(types.SOCKET_ONOPEN, {
//       message
//     })
//   })
// }
//
// export const switchThread = ({ commit }, payload) => {
//   commit(types.SOCKET_ONOPEN, payload)
// }

export const getPumpsCount = (context) => {
  console.log(context)
  this.$socket.sendObj({action: 'getpumpscount', key: this.$auth.token()})
}

export const scanBTNozzlesOn = (context) => {
  console.log(context)
  this.$socket.sendObj({action: 'scanon', key: this.$auth.token()})
}

export const setStation = async ({ commit}) => {
 
  const res = await axios.get("/system")
  commit(types.SET_STATION, res.data)
}

export const getAnalogSetting = function (context, block) {
  console.log("getAnalogSetting",block)
  this._vm.$socket.sendObj({action: 'getanalogsettings', block: block, key: this._vm.$auth.token()})
}

export const setAnalogSetting =  function (context, setting) {
  console.log("getAnalogSetting",setting)
  this._vm.$socket.sendObj({action: 'setanalogsettings', setting: setting, key: this._vm.$auth.token()})
}

export const clearStatuses = function (context) {
  context.commit(types.CLEAR_STATUSES)
}
